<template>
  <div>
    <a-row :gutter="16">
      <a-col :span="24">
        <a-form-item label="Template Type">
          <a-select size="large" class="w-100" :value="dataTemplate.type" :disabled="isEdit"
            @change="(e) => handleChange(e, 'type')">
            <a-select-option v-for="(item, index) in daftarTemplate" :key="index" :value="item.key">
              {{ item.nama }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <template v-if="['skill', 'knowledge', 'teacher_note'].includes(dataTemplate.type)">
        <a-col :span="12">
          <a-form-item label="Predicate">
            <a-select size="large" class="w-100" :value="dataTemplate.id_predikat"
              @change="(e) => handleChange(e, 'id_predikat')">
              <a-select-option v-for="item in listPredicate" :key="item.id" :value="item.id">
                {{ item.nama }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Alias">
            <a-input disabled :value="selectedPredicate.alias" size="large" />
          </a-form-item>
        </a-col>
      </template>
      <template v-else>
        <a-col :span="12">
          <a-form-item label="Predicate">
            <a-select size="large" class="w-100" :value="dataTemplate.option" @change="(e) => handleChange(e, 'option')">
              <a-select-option v-for="item in daftarOption" :key="item.nama" :value="item.nama">
                {{ item.nama }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Alias">
            <a-input v-model="dataTemplate.alias" size="large" />
          </a-form-item>
        </a-col>
      </template>
      <a-col :span="24">
        <a-form-item label="Description">
          <a-textarea v-model="dataTemplate.description" :auto-size="{ minRows: 5, maxRows: 7 }" />
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-form-item label="Variables">
          <div class="d-flex">
            <div v-for="item in config.templateVariables" :key="item.name" class="mr-3">
              <a-button class="border-primary text-primary" @click.prevent="handleChange(item.code, 'variable')"
                size="large">
                {{ item.name }}
              </a-button>
            </div>
          </div>
        </a-form-item>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import config from '@/config'
const daftarOption = [
  {
    nama: 'A',
  },
  {
    nama: 'B',
  },
  {
    nama: 'C',
  },
  {
    nama: 'D',
  },
  {
    nama: 'E',
  },
]
export default {
  props: {
    dataTemplate: {
      type: Object,
      required: true,
    },
    isEdit: {
      type: Boolean,
    },
  },
  data() {
    return {
      config,
      daftarOption,
      listPredicate: [
        {
          id: 7,
          nama: 'A',
          alias: 'Excelent',
        },
        {
          id: 2,
          nama: 'B',
          alias: 'Good',
        },
        {
          id: 3,
          nama: 'C',
          alias: 'Enough',
        },
        {
          id: 4,
          nama: 'B',
          alias: 'Bad',
        },
      ],
      endOpen: false,
      selectedPredicate: {
        alias: null,
      },
    }
  },
  mounted() {
    this.fetchPredicate()
  },
  methods: {
    findSelectedPredicate(id) {
      const target = this.listPredicate.find(predicate => predicate.id === id)
      if (target) this.selectedPredicate = target
    },
    handleChange(value, column) {
      const payload = { value, column }
      this.$emit('handle-change', payload)

      if (column === 'id_predikat' && value) {
        this.findSelectedPredicate(value)
      }
      //   this.selectedPredicate = {
      //     alias: null,
      //   }
      // }
    },
    async fetchPredicate() {
      try {
        const data = await this.$store.dispatch('master/FETCH_PREDICATE', { page: 'all' })
        this.listPredicate = data

        if (this.dataTemplate.id_predikat) {
          this.findSelectedPredicate(this.dataTemplate.id_predikat)
        }
      } catch (error) {
        return this.$notification.error({
          message: 'Error',
          description: 'The process of fetching predicate encountered problems. Please try again later',
        })
      }
    },
  },
  computed: {
    daftarTemplate() {
      const daftar = [
        {
          key: 'spiritual',
          nama: 'Spiritual Attitude',
        },
        {
          key: 'social',
          nama: 'Social Attitude',
        },
        {
          key: 'knowledge',
          nama: 'Knowledge',
        },
        {
          key: 'skill',
          nama: 'Skill',
        },
        {
          key: 'extracurricular',
          nama: 'Extracurricular',
        },
        {
          key: 'teacher_note',
          nama: 'Teacher Note',
        },
        {
          key: 'activities',
          nama: 'Activities',
        },
      ]

      return daftar
    },
  },
}
</script>

<style></style>
